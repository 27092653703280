<template>
  <div class="  mb-15">
    <div
      style="margin-top: 60px"
    >
      <post-component
        v-if="!loader.loading"
        :quick_comment="!post.has_children"
        :limit="0"
        :post-item="post"
        :user="user"
        @replyPost="(post, index)=>replyPost(post, index)"
        @openComment="()=>loader.comment = true"
        @imageViewer="(images)=> {postimages = images; loader.dialog = true}"
      ></post-component>
    </div>

<!--        <comment-component
          :quick_comment="false"
          :limit="15"
          :user="user"
          :post_id="post.id"
          :nbcomment="post.nbcomment"
          @initlogin="()=>$emit('initlogin')"
        ></comment-component>-->

    <div v-if="post.has_children">
      <post-child-component v-if="post.category.slug == 5" v-for="(child, $index) in post.children"
                            :key="'child-'+child.id"
                            :postimage="child.postimages[0]"
                            :postItem="child"
                            :user="user"
                            :limit="1"
                            :index="$index"
      ></post-child-component>
      <template v-else-if="post.category.slug == 4">
        <v-card-text>Episodes</v-card-text>
        <v-list dense>
          <template v-for="(item, index) in post.children">
            <v-divider
              :key="index"
            ></v-divider>

            <v-list-item
              :key="item.title"
              @click="postimages = item.postimages; loader.dialog = true; postdetail = item"
              dense
            >
              <img
                class="mr-3"
                width="50"
                :src="item.postimages[0].image.uploaddir+'150_'+item.postimages[0].image.image"
                :lazy-src="require('@/assets/images/holder.jpg')"
                contain
              >

              <v-list-item-content>
                <v-list-item-title class="title-inline">
                  {{ item.content }}
                  <v-spacer></v-spacer>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.creationdate }} -
                  <v-icon small>{{ icons.mdiHeartOutline }}</v-icon>
                  {{ item.nbkola }}
                  <v-icon small>{{ icons.mdiCommentOutline }}</v-icon>
                  {{ item.nbcomment }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
              </v-list-item-icon>
              <v-list-item-action>
                <v-chip small>
                  {{ item.postimages.length }} pages
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>

      </template>
    </div>

    <v-dialog fullscreen style="z-index: 1002" v-model="loader.dialog">
      <image-viewer @close="()=>loader.dialog = false" :post="postdetail" :postimages="postimages"
      ></image-viewer>
    </v-dialog>

    <v-dialog
      v-model="loader.replypost"
      max-width="600px" persistent
    >
      <post-reply-form-component
        v-model="loader.replypost"
        :postedit="{}"
        :mainpost="post"
        @posted="posted"
        @close="cancelpost"
      ></post-reply-form-component>
    </v-dialog>

    <v-sheet
      v-if="loader.loading"
      :color="`grey darken-2 `"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="500"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiChevronLeft,
  mdiPlus,
  mdiDotsVertical,
  mdiBookmark,
  mdiShareVariant,
  mdiHeart,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import { useRouter } from '@/utils'
import PostComponent from '@/components/PostComponent'
import ImageViewer from '@/components/ImageViewer'
import CommentComponent from '@/components/CommentComponent.vue'
import PostChildComponent from '@/components/PostChildComponent.vue'
import PostReplyFormComponent from '@/components/PostReplyFormComponent.vue'

export default {
  components: {
    PostReplyFormComponent,
    PostChildComponent,
    CommentComponent,
    ImageViewer,
    PostComponent
  },
  setup() {
    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      dialog: false,
      comment: false,
      replypost: false,
    })
    const post = ref({})
    const postdetail = ref({})
    const postimages = ref([])
    const children = ref([])
    const user = Drequest.getUser()

    const { router } = useRouter()
    const route = router.currentRoute
    let idpost = route.params.id
    if (!idpost) {
      idpost = route.query.id
      if (!idpost) idpost = route.query.post
    }
    const init = () => {
      Drequest.api(`detail.post?id=${idpost}${user.id ? `&user_id=${user.id}` : ''}`)
        .get(response => {
          console.log(response)
          loader.value.loading = false
          post.value = response.post
          if (post.value.children) {
            children.value = post.value.children
          }
        })
    }
    init()

    const posted = post => {
      console.log(post)
      loader.value.replypost = false
      children.value.unshift(post)

    }
    const replyPost = (post, index) => {
      console.log(post)
      loader.value.replypost = true
    }
    const cancelpost = () => {
      loader.value.replypost = false
    }

    return {
      posted,
      replyPost,
      cancelpost,

      user,
      post,
      postimages,
      loader,
      postdetail,

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiBookmark,
        mdiShareVariant,
        mdiHeart,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.sticky-top-0 {

  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;

}
</style>
